let accordions = document.querySelectorAll('.accordion--header');

for (let i=0; i<accordions.length; i++) {
    accordions[i].onclick = function(){
        let bodyElements = this.parentElement.parentElement.getElementsByClassName('accordion--body');
        for (let j=0; j<bodyElements.length; j++) {
            bodyElements[j].className = 'accordion--body';
            bodyElements[j].previousElementSibling.classList.remove('active');
        }

        this.classList.toggle('active');
        this.nextElementSibling.className = 'accordion--body opened';
    };
}
