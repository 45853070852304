import axios from 'axios';

(function ()
{
    let Url = '';
    let Type = '';
    let Loading = false;
    let Container = null;
    let Category = '';
    let From = 0;
    let Size = 0;
    let Scrolling = true;
    let HtmlGroup = [];
    let ContainerClassName = '';

    function getByClassName(name)
    {
        if (document.getElementsByClassName(name).length) {
            return document.getElementsByClassName(name)[0];
        }

        return null;
    }

    function getScrollTopMax()
    {
        let footer = getByClassName('container footer');
        let footerHeight = footer ? footer.offsetHeight : 0;

        return document.documentElement.scrollHeight - footerHeight - document.documentElement.clientHeight;
    }

    function addItemsToHtml(items)
    {
        let wrapper = document.createElement('div');
        wrapper.className = ContainerClassName;


        for (let i in items) if (items.hasOwnProperty(i)) {
            let item = document.createElement('div');
            item.innerHTML = items[i];

            let itemObj = item.childNodes[0];
            itemObj.className+=' teaser'+(parseInt(i)+1);

            wrapper.append(itemObj);
        }

        Container.append(wrapper);
    }

    function objLength(obj)
    {
        let num = 0;

        if (obj) {
            for (let i in obj) if (obj.hasOwnProperty(i)) {
                num++;
            }
        }

        return num;
    }

    function addResult(result)
    {
        if (!result || !result.hasOwnProperty('articles')) return;

        if (!objLength(result.articles)) {
            Scrolling = false;
            return;
        }

        From = From + Size;

        HtmlGroup = [];

        for (let i in result.articles) if (result.articles.hasOwnProperty(i)) {
            HtmlGroup.push(result.articles[i]);
            if (HtmlGroup.length === 3) {
                addItemsToHtml(HtmlGroup);
                HtmlGroup = [];
            }
        }

        if (HtmlGroup.length) {
            addItemsToHtml(HtmlGroup);
            Scrolling = false;
        }
    }

    function scroll()
    {
        if (Scrolling && window.scrollY >= getScrollTopMax()) {
            loadPage(addResult);
        }
    }

    function loadPage(callback)
    {
        if (Loading) return;
        Loading = true;

        let url = Url;
        url = url.replace(/{Type}/ig, Type);
        url = url.replace(/{From}/ig, From);
        url = url.replace(/{Size}/ig, Size);
        url = url.replace(/{Category}/ig, Category);

        get_data(url, function (result)
        {
            Loading = false;
            callback(result);
            scroll();
        });
    }

    let Cache = {};
    let GetDataCache = {};
    let GetDataQueue = {};

    function get_data(url, callback, returnData)
    {
        if (!url) {
            console.debug('get_data', 'no URL');
            return callback(null);
        }

        if (Cache) {
            if (GetDataCache[url] !== undefined) {
                return callback(GetDataCache[url]);
            }

            if (GetDataQueue[url] !== undefined) {
                GetDataQueue[url].push(callback);
                return;
            }

            GetDataQueue[url] = [callback];
        }

        axios.get(url,
            {
                headers: {
                    //'Accept': 'application/json',
                },
                responseType: returnData === 'binary' ? 'arraybuffer' : null,
            })
            .then((response) =>
            {
                let result = response.data;
                if (Cache) {
                    GetDataCache[url] = result;
                    if (Cache) {
                        for (let i in GetDataQueue[url]) if (GetDataQueue[url].hasOwnProperty(i)) {
                            GetDataQueue[url][i](GetDataCache[url]);
                        }

                        GetDataQueue[url] = [];
                    }
                }
                else {
                    callback(result);
                }
            })
            .catch((error) =>
            {
                console.debug('get_data', error);
                callback(null);
            });
    }

    function getCategoryCut(name)
    {
        let parts = location.pathname.substr(name.length).split('/');

        for (let i in parts) if (parts.hasOwnProperty(i)) {
            if (parts[i] === '') {
                parts.splice(i, 1);
            }
        }

        return parts.join(',');
    }

    function getCategory()
    {
        switch (Type) {
            case 'resort':
                return getCategoryCut('/');
            case 'topic':
                return getCategoryCut('/themen/');
            case 'location':
                return getCategoryCut('/ort/');
            case 'author':
                return getCategoryCut('/author/');
            case 'generic':
                return 'true';
        }

        return '';
    }

    Container = getByClassName('container__infinityScroll');

    if (Container) {
        Url = Container.getAttribute('data-url');
        Type = Container.getAttribute('data-type');
        From = parseInt(Container.getAttribute('data-from'));
        Size = parseInt(Container.getAttribute('data-size'));
        ContainerClassName = Container.getAttribute('data-container-class');

        Category = getCategory();

        if (Url && Type && Category) {
            document.addEventListener('scroll', scroll, false);
            scroll();
        }
    }
})();
