import axios from 'axios';

(function ()
{
    let Url = '';
    let Loading = false;
    let Container = null;

    function getById(id)
    {
        return document.getElementById(id);
    }

    function addResult(result)
    {
        if (!result || !result.hasOwnProperty('articles')) return;

        let html='';

        if (result.hasOwnProperty('totalFound') && result.totalFound>=1)
        {
            html=result.articles[0];
        }

        Container.innerHTML=html;
    }

    function loadPage(callback)
    {
        if (Loading) return;
        Loading = true;

        console.log('loadPage');

        get_data(Url, function (result)
        {
            Loading = false;
            callback(result);
        });
    }

    function get_data(url, callback, returnData)
    {
        if (!url) {
            console.debug('get_data', 'no URL');
            return callback(null);
        }

        axios.get(url,
            {
                headers: {
                    //'Accept': 'application/json',
                },
                responseType: returnData === 'binary' ? 'arraybuffer' : null,
            })
            .then((response) =>
            {
                callback(response.data);
            })
            .catch((error) =>
            {
                console.debug('get_data', error);
                callback(null);
            });
    }

    function loadNews()
    {
        loadPage(addResult);
    }

    Container = getById('Components.Molecule.BreakingNews');

    if (Container) {
        Url = Container.getAttribute('data-url');

        if (Url) {
            loadNews();
        }
    }
})();
