let isMobile = (/iPad|iPhone|iPod|Android/.test(navigator.userAgent));
(function (isMobile)
{
    /* helper to avoid browser bug: overflow-x:hidden with overflow-y:visible and position:relative containing position:absolute element cut*/

    let mainmenu = document.getElementsByClassName('real_mainnavigation');

    if (!mainmenu.length) return;

    let scrollbar = mainmenu[0].getElementsByClassName('navbar-nav');

    if (!scrollbar.hasOwnProperty(0)) return;

    let scrollArrowLeft = mainmenu[0].getElementsByClassName('organism-mainnavigation__more left')[0];
    let scrollArrowRight = mainmenu[0].getElementsByClassName('organism-mainnavigation__more right')[0];

    let setScrollArrows = function (obj)
    {
        let pos = obj.scrollLeft / (obj.scrollWidth-obj.offsetWidth) * 100;
        if (obj.scrollWidth-obj.offsetWidth<20) pos = -1;

        if (scrollArrowLeft) {
            if (pos > 1) {
                scrollArrowLeft.classList.add('show');
            }
            else {
                scrollArrowLeft.classList.remove('show');
            }

            if (pos === -1) {
                scrollArrowLeft.classList.remove('show');
            }
        }

        if (scrollArrowRight) {
            if (pos < 99) {
                scrollArrowRight.classList.add('show');
            }
            else {
                scrollArrowRight.classList.remove('show');
            }

            if (pos === -1) {
                scrollArrowRight.classList.remove('show');
            }
        }
    };

    setScrollArrows(scrollbar[0]);

    let dropdowns = scrollbar[0].getElementsByClassName('atom-menuitem__dropdown dropdown-menu');
    window.addEventListener('resize', function ()
    {
        setScrollArrows(scrollbar[0]);

    }, false);

    scrollbar[0].addEventListener('scroll', function ()
    {
        setScrollArrows(this);

        if (isMobile) return;

        for (let i in dropdowns) if (dropdowns.hasOwnProperty(i)) {
            dropdowns[i].style.marginLeft = (this.scrollLeft * -1) + 'px';
        }
    }, false)
})(isMobile);

(function (isMobile)
{
    /* remove dropdown on Mobile -> buggy */

    if (!isMobile) return;

    let mainmenu = document.getElementsByClassName('real_mainnavigation');

    if (!mainmenu.length) return;

    let wrapper = mainmenu[0].getElementsByClassName('molecule-mainulevel__list__wrapper');

    if (!wrapper.length) return;

    let items = [];
    [].push.apply(items, wrapper[0].getElementsByClassName('dropdown'));
    [].push.apply(items, wrapper[0].getElementsByClassName('dropdown-toggle'));

    for (let i in items) if (items.hasOwnProperty(i)) {
        items[i].classList.remove('dropdown');
        items[i].classList.remove('dropdown-toggle');
    }
})(isMobile);

(function (isMobile)
{
    /* mainmenu is now usable by mobile */

    let mainmenu = document.getElementsByClassName('real_mainnavigation');

    if (!mainmenu.length) return;

    let burgermenu = document.getElementsByClassName('real_burgermenu');

    if (!burgermenu.length) return;

    let isVisible = function ()
    {
        return !(burgermenu[0].offsetTop === 0 && burgermenu[0].offsetHeight === 0);
    };

    let items = [];
    [].push.apply(items, mainmenu[0].getElementsByClassName('dropdown-toggle'));

    let reset = function ()
    {
        for (let i in items) if (items.hasOwnProperty(i)) {
            items[i].parentNode.classList.remove('show');
        }
    };

    let parentNodeHasDropdown = function (obj)
    {
        if (obj.parentNode && obj.parentNode.classList) {
            if (obj.parentNode.classList.contains('dropdown'))
                return true;

            return parentNodeHasDropdown(obj.parentNode);
        }

        return false;
    };

    let reset_timer = 0;

    for (let i in items) if (items.hasOwnProperty(i)) {
        items[i].addEventListener('click', function (e)
        {
            if (isVisible()) {
                clearTimeout(reset_timer);

                e.preventDefault();

                if (!this.parentNode.classList.contains('show')) {
                    reset();
                }

                this.parentNode.classList.toggle('show');
            }
        });
    }

    let func_reset = function (e)
    {
        if (!parentNodeHasDropdown(e.target)) {
            reset_timer = setTimeout(function ()
            {
                reset();
            }, 100);
        }
    };

    document.addEventListener('mouseup', func_reset, false);
    document.addEventListener('touchend', func_reset, false);
})(isMobile);
