(function () {
    const a2hsContainer = document.querySelector('.atom-a2hs');
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const isAndroid = /Android/.test(winNav.userAgent);
    let cookieMobileInfo = 'a2hsHintAlreadyShown';
    let deferredPrompt;

    if('serviceWorker' in navigator) {
        navigator.serviceWorker
        .register('/sw.js')
        .then(function() {
            if (a2hsContainer && isAndroid && isChromium && getMobileInfoCookie() === '') {
                setMobileInfoCookie();
                a2hsContainer.classList.remove('hide');
            }
        })
        .catch(function(err) {
            console.log("Service Worker not registered:", err)
        });
    }

    const cancelBtn = a2hsContainer.querySelector('#close-a2hs');
    cancelBtn.addEventListener('click', function(e) {
        a2hsContainer.classList.add('hide');
    });

    window.addEventListener('beforeinstallprompt', function(e) {
        e.preventDefault();
        deferredPrompt = e;

        const addBtn = a2hsContainer.querySelector('.add-to-homescreen');
        addBtn.addEventListener('click', function(e) {
            if(!deferredPrompt) {
                a2hsContainer.classList.add('hide');
                return;
            }

            a2hsContainer.classList.add('hide');
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then(function() {
                deferredPrompt = null;
            });
        });
    });

    function setMobileInfoCookie() {
        const d = new Date();
        const exdays = 7;
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cookieMobileInfo + "=true;" + expires + ";path=/";
    }

    function getMobileInfoCookie() {
        const name = cookieMobileInfo + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}());
