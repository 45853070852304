function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

window.plenigoStatistics = function(data) {
    //console.log('In plenigoStatistics.');
    if (typeof dataLayer === "undefined") {
        return;
    }
    //console.log('Plenigo Data: ', data);

    if (data.action === "submit" && data.pageName !== "login") {
        let stats = {
            hitType: "event",
            pageName: data.pageName ? data.pageName : '',
            eventAction: data.action,
            eventLabel: "plenigo"
        };
        dataLayer.push(stats);
    }
};




(function () {
    const plenigoFunction = function () {
        let registrationSuccessUrl = '/erfolgreiche-bestellung';
        let testMode = document.body.dataset.ptest ? true: false;

        let loginButton = document.querySelectorAll('.login');
        let logoutButton = document.querySelectorAll('.logout');
        let testOneMonthButton = document.querySelectorAll('.test-one-month');
        let aboCustomerButton = document.querySelectorAll('.abo-customer');

        let accountListItem = document.querySelectorAll('.accountContainer');
        let cookie = getCookie('plenigo_user');


        let plenigoButtons = document.querySelectorAll('[data-plenigo]');
        if (plenigoButtons) {
            plenigoButtons.forEach(item => {
                let checkoutConfigNewCustomer = {
                    paymentData: item.dataset.plenigo,
                    startWithRegistration: "true",
                    sourceUrl: null,
                    targetUrl: document.body.dataset.ptarget,
                    affiliateId: null,
                    elementId: null
                };
                if (!item.classList.contains('inBackend')) {
                    item.addEventListener('click', function () {
                        plenigo.checkout(checkoutConfigNewCustomer);
                    });
                }
            });
        }

        loginButton.forEach(item => {
            item.addEventListener('click', function () {
                plenigo.login();
            });

            if (cookie) {
                item.classList.add('hide');
                accountListItem.forEach(item => {
                    item.classList.add('show');
                });
                logoutButton.forEach(item => {
                    item.addEventListener('click', function () {
                        plenigo.logout();
                    });
                    item.classList.add('show');
                    item.classList.remove('hide');
                });
            } else {
                accountListItem.forEach(item => {
                    item.classList.add('hide');
                    item.classList.remove('show');
                });
                logoutButton.forEach(item => {
                    item.classList.add('hide');
                    item.classList.remove('show');
                });
            }
        });

        let checkoutConfigNewCustomer = {
            paymentData: document.body.dataset.p0,
            startWithRegistration: "true",
            sourceUrl: null,
            targetUrl: document.body.dataset.ptarget,
            affiliateId: null,
            elementId: null
        };

        let checkoutConfigAboCustomer = {
            paymentData: document.body.dataset.p1,
            startWithRegistration: "true",
            sourceUrl: null,
            targetUrl: document.body.dataset.ptarget,
            affiliateId: null,
            elementId: null
        };

        testOneMonthButton.forEach(item => {
            item.addEventListener('click', function () {
                plenigo.checkout(checkoutConfigNewCustomer);
            });
        });

        aboCustomerButton.forEach(item => {
            item.addEventListener('click', function () {
                plenigo.checkout(checkoutConfigAboCustomer);
            });
        });
    };

    document.addEventListener('readystatechange', plenigoFunction());
})();
