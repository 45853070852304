(function ()
{
    let burgermenu = document.getElementsByClassName('real_burgermenu');

    if (!burgermenu.length) return;

    let items = [];
    [].push.apply(items,burgermenu[0].getElementsByClassName('accordion'));
    [].push.apply(items,burgermenu[0].getElementsByClassName('atom-menuitem__link dropdown-toggle'));

    for (let i in items) if (items.hasOwnProperty(i)) {
        items[i].addEventListener('click', function (e)
        {
            e.preventDefault();

            this.classList.toggle('show');

            let dropdown = this.nextElementSibling;

            dropdown.classList.toggle('show');

            if (dropdown.style.maxHeight) {
                dropdown.style.maxHeight = null;
            } else {
                dropdown.style.maxHeight = (dropdown.scrollHeight+15) + 'px';
            }
        });
    }
})();

