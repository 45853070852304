(function(){
    const scrollToTopButton = document.getElementById('scrolltotop');

    const scrollFunc = () => {
        let y = window.scrollY;

        if (y > 0) {
            scrollToTopButton.className = 'atom-scrolltotop show';
        } else {
            scrollToTopButton.className = 'atom-scrolltotop hide';
        }
    };

    window.addEventListener('scroll', scrollFunc);

    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;

        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 12);
        }
    };

    scrollToTopButton.onclick = function(e) {
        e.preventDefault();
        scrollToTop();
    };
})();
