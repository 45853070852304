(function () {
	let cookieBanner=document.getElementById('CookieBanner');

	if (!cookieBanner) return; /* <-- IMPORTANT OR SCRIPT ERROR IF NOT EXISTS */

	let closeCookieBannerBtns =  document.querySelectorAll('.close_CookieBanner');
	closeCookieBannerBtns.forEach(function(btn){
		btn.addEventListener("touch",function () {
			cookieBanner.style.display = 'none';
		});
		btn.addEventListener("click",function () {
			cookieBanner.style.display = 'none';
		});
	});

	let openCookieBannerBtns =  document.querySelectorAll('.open_CookieBanner');
	openCookieBannerBtns.forEach(function(btn){
		btn.addEventListener("touch",function () {
			cookieBanner.style.display = 'block';
		});
		btn.addEventListener("click",function () {
			cookieBanner.style.display = 'block';
		});
	});

	let cookienotice = getCookie('cookienotice');
	if (!cookienotice) {
		cookieBanner.style.display = 'block';
		document.cookie="cookienotice\x3dtrue; path\x3d/"
	}

	function getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i <ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}());
