(function(){
    let list = document.getElementsByClassName('atom-regiontag__link');
    let all_regions=document.getElementsByClassName('all-regions');
    let regions={};

    for (let i in list) if (list.hasOwnProperty(i)) if (list[i].getAttribute('data-region')&&list[i].getAttribute('data-uripathsegment'))
    {
        let	region_name=list[i].getAttribute('data-region');

        if (!regions.hasOwnProperty(region_name))
        {
            regions[region_name]={};
        }

        if (!regions[region_name].hasOwnProperty(list[i].getAttribute('data-uripathsegment')))
        {
            regions[region_name][list[i].getAttribute('data-uripathsegment')]={
                active:list[i].classList.contains('active'),
                name:list[i].getAttribute('data-uripathsegment'),
                buttons:[],
            };
        }

        regions[region_name][list[i].getAttribute('data-uripathsegment')].buttons.push(list[i]);

        list[i].addEventListener('click',function()
        {
            regions[this.getAttribute('data-region')][this.getAttribute('data-uripathsegment')].active=!regions[this.getAttribute('data-region')][this.getAttribute('data-uripathsegment')].active;
            update();
            this.blur();
        },false);
    }

    let regions_walker = function(callback)
    {
        for(let  r in regions) if (regions.hasOwnProperty(r))
        {
            for(let  i in regions[r]) if (regions[r].hasOwnProperty(i))
            {
                callback(r,regions[r][i]);
            }
        }
    };

    let all_region_choosed=function(region_search)
    {
        let choosed_all=true;

        regions_walker(function(region_name,region,i)
        {if (region_search===region_name)

            if (region_search===region_name&&!region.active)
            {
                choosed_all=false;
            }
        });

        return choosed_all;
    };

    let all_buttons_active=function()
    {
        for (let i in regions) if (regions.hasOwnProperty(i))
        {
            let all_choosed=all_region_choosed(i);

            for(let r in all_regions) if(all_regions.hasOwnProperty(r))
            {
                if (all_regions[r].getAttribute('data-region')===i)
                {
                    if (all_choosed)
                    {
                        all_regions[r].classList.add('active');
                    }
                    else
                    {
                        all_regions[r].classList.remove('active');
                    }
                }
            }
        }
    };

    let choose_buttons_active=function()
    {
        let active_region_found=false;

        regions_walker(function(region_name,region)
        {
            if (region.active)
            {
                active_region_found=true;
            }
        });

        if (active_region_found)
        {
            enableLink();
        }
        else
        {
            disableLink();
        }

        all_buttons_active();
    };

    let setButtonsActiveStatus=function()
    {
        regions_walker(function(region_name,region)
        {
            for(let b in region.buttons) if (region.buttons.hasOwnProperty(b))
            {
                if (region.active)
                {
                    region.buttons[b].classList.add('active');
                }
                else
                {
                    region.buttons[b].classList.remove('active');
                }
            }
        });
    };

    for(let b in all_regions) if (all_regions.hasOwnProperty(b)) if (all_regions[b].getAttribute('data-region'))
    {
        all_regions[b].addEventListener('click',function()
        {
            let choosed_all=all_region_choosed(this.getAttribute('data-region'));

            for(let i in regions[this.getAttribute('data-region')])
            {
                if (regions[this.getAttribute('data-region')].hasOwnProperty(i))
                {
                    regions[this.getAttribute('data-region')][i].active=!choosed_all;
                }
            }

            update();
            this.blur();
        });
    }

    let deleteBtn = document.getElementsByClassName('my-region-delete');

    for (let d in deleteBtn)  if (deleteBtn.hasOwnProperty(d))
    {
        deleteBtn[d].addEventListener('click', function ()
        {
            for(let r in regions) if (regions.hasOwnProperty(r))
                for(let i in regions[r]) if (regions[r].hasOwnProperty(i))
                {
                    regions[r][i].active=false;
                }

            update();
            this.blur();
        });
    }

    let disableLink = function () {
        let buttons = document.getElementsByClassName('my-region-tab-navigation__button');
        for(let i in buttons) if (buttons.hasOwnProperty(i))
        {
            buttons[i].parentElement.classList.add('is-disabled');
            buttons[i].setAttribute('aria-disabled', 'true');
            buttons[i].setAttribute('disabled', 'true');
        }
    };

    let enableLink = function () {
        let buttons = document.getElementsByClassName('my-region-tab-navigation__button');
        for(let i in buttons) if (buttons.hasOwnProperty(i))
        {
            buttons[i].parentElement.classList.remove('is-disabled');
            buttons[i].removeAttribute('aria-disabled');
            buttons[i].removeAttribute('disabled', 'true');
        }
    };

    let getAllChoosedOrte=function()
    {
        let list=[];

        regions_walker(function(region_name,region)
        {
            if (region.active)
            {
                list.push(region.name);
            }
        });

        list.sort();

        return list;
    };

    let chooseBtn = document.getElementsByClassName('my-region-choose');

    for (let c in chooseBtn) if (chooseBtn.hasOwnProperty(c))
    {
        chooseBtn[c].addEventListener('click', function () {
            window.open('/ort/'+getAllChoosedOrte().join('/')+'/', '_self');
            this.blur();
        });
    }

    let update=function()
    {
        setButtonsActiveStatus();
        choose_buttons_active();
        all_buttons_active();
    };

    update();
})();
